<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="4">
        <div>市场</div>
        <el-select v-model="mk_id" placeholder="请选择市场">
          <el-option
            v-for="item in market"
            :key="item.id"
            :label="item.comp_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col class="right" :span="4">
        <span class="demonstration">月份</span>
        <el-date-picker
          v-model="day"
          type="month"
          value-format="yyyy-MM"
          format="yyyy-MM"
          placeholder="选择月份"
        >
        </el-date-picker>
      </el-col>
      <el-col class="right" :span="4">
        <el-button type="primary" @click="getRankingTabel">查询</el-button>
        <el-button type="primary" @click="showModels = true">新增</el-button>
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      border
      style="margin-top:20px"
    >
      <el-table-column label="排名" prop="ranking"> </el-table-column>
      <el-table-column label="月份" prop="day"> </el-table-column>
      <el-table-column label="市场名称">
        <template slot-scope="scope">
          {{ scope.row.market_information.comp_name }}
        </template>
      </el-table-column>
      <el-table-column label="分数" prop="score"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="rankingAlter(scope.row)"
            >操作</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="1"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :visible.sync="showModel"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="ruleForm" label-width="80px">
        <el-form-item label="分数">
          <el-input v-model="ruleForm.score" type="number" min="1"></el-input>
        </el-form-item>
        <el-form-item label="排名">
          <el-input
            v-model="ruleForm.rankingNum"
            type="number"
            min="1"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="putRanking">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="showModels"
      width="30%"
      :before-close="handleCloses"
    >
      <el-form ref="form" :rules="rules" :model="ruleForms" label-width="80px">
        <el-form-item label="市场" prop="mk_id">
          <el-select v-model="ruleForms.mk_id" placeholder="请选择市场">
            <el-option
              v-for="item in market"
              :key="item.id"
              :label="item.comp_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期" prop="day">
          <el-date-picker
            v-model="ruleForms.day"
            type="month"
            value-format="yyyy-MM"
            format="yyyy-MM"
            placeholder="选择月份"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="分数" prop="score">
          <el-input v-model="ruleForms.score" type="number" min="1"></el-input>
        </el-form-item>
        <el-form-item label="排名" prop="ranking">
          <el-input
            v-model="ruleForms.ranking"
            type="number"
            min="1"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit('form')">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  rankingTabel,
  getmarket,
  putRanking,
  postRanking
} from '../../../api/layout'
export default {
  name: 'ranking',
  data () {
    return {
      page: 1,
      limit: 10,
      mk_id: '',
      day: '',
      tableData: [],
      total: 0,
      market: '',
      showModel: false,
      showModels: false,
      id: '',
      ruleForm: {
        score: '',
        rankingNum: ''
      },
      rules: {
        mk_id: [{ required: true, message: '此项为必填', trigger: 'blur' }],
        day: [{ required: true, message: '此项为必填', trigger: 'blur' }],
        ranking: [{ required: true, message: '此项为必填', trigger: 'blur' }],
        score: [{ required: true, message: '此项为必填', trigger: 'blur' }]
      },
      ruleForms: {
        mk_id: '',
        day: '',
        ranking: '',
        score: ''
      }
    }
  },
  methods: {
    getRankingTabel () {
      rankingTabel({
        page: this.page,
        limit: this.limit,
        day: this.day,
        mk_id: this.mk_id
      }).then(res => {
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    getTabel () {
      this.page = 1
      this.limit = 10
      if (!this.mk_id && !this.day) return
      this.getRankingTabel()
    },
    // 市场
    getMarket () {
      getmarket().then(res => {
        this.market = res.data
      })
    },
    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.limit = val
      this.getRankingTabel()
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.page = val
      this.getRankingTabel()
    },
    rankingAlter (row) {
      this.ruleForm.score = row.score
      this.ruleForm.rankingNum = row.ranking
      this.id = row.id
      this.showModel = true
    },
    handleClose () {
      this.showModel = false
      this.ruleForm.score = ''
      this.ruleForm.rankingNum = ''
    },
    putRanking () {
      putRanking(this.id, {
        score: this.ruleForm.score,
        ranking: this.ruleForm.rankingNum
      })
        .then(res => {
          this.handleClose()
          this.$message.success(res.data)
          this.getRankingTabel()
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    handleCloses () {
      this.showModels = false
      this.ruleForms = {
        mk_id: '',
        day: '',
        ranking: '',
        score: ''
      }
    },
    submit (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          postRanking(this.ruleForms)
            .then(res => {
              this.$message.success(res.data)
              this.handleCloses()
              this.getRankingTabel()
            })
            .catch(err => {
              this.$message.error(err.msg)
            })
        } else {
          return false
        }
      })
    }
  },
  mounted () {
    this.getRankingTabel()
    this.getMarket()
  }
}
</script>

<style lang="less" scoped>
.right {
  margin-left: 20px;
}
.el-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer {
  text-align: right;
  margin-top: 10px;
}
</style>
